import React from 'react'
import { create } from 'jss'
import { ThemeProvider } from '@mui/material/styles'

import { JssProvider } from 'react-jss'
import { jssPreset } from '@mui/styles'
import theme, { errorTheme } from './theme.ts'

const insertionPoint = document.getElementById('jss-insertion-point')
const jss = create(jssPreset())

jss.setup({ insertionPoint })

const MuiTheme = (props) => {
  const { children } = props

  return (
    <JssProvider jss={jss}>
      <ThemeProvider theme={{
        ...theme
      }}>
        {children}
      </ThemeProvider>
    </JssProvider>
  )
}

export default MuiTheme

export const ErrorTheme = props => (
  <ThemeProvider theme={errorTheme}>
    {props.children}
  </ThemeProvider>
)
