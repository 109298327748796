/* eslint-disable no-jquery/no-jquery-constructor, no-jquery/no-find-collection, no-jquery/no-other-methods, no-jquery/no-attr, no-jquery/no-html */

import React from 'react';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import MuiTheme from '@/shared/components/MuiTheme.jsx';
import theme from '../shared/components/theme';
import { createEmbeddedComponentStore } from './embeddedComponentStoreFactory';

export const embeddedComponentStore = createEmbeddedComponentStore(
  (ReactComponentClass, data, contentHtml, id, logging) => {
    return <Observer>
        {() => {
          return <MuiTheme>
            <ReactComponentClass
              {...toJS(data)}
              theme={theme}
              assignProperties={(changes: object) => {
                runInAction(() => {
                  logging && console.log(`setting "${id}" to ${JSON.stringify(changes)}`);
                  Object.assign(data, changes);
                });
              }}>
              {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} />}
            </ReactComponentClass>
          </MuiTheme>;
        }}
      </Observer>;
  }, store => {
    makeAutoObservable(store);
  });

embeddedComponentStore.init();
