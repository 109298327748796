import { createTheme, ThemeOptions } from '@mui/material/styles';

/*
 * color-blindness.com/color-name-hue
 */
export const amaranth = '#e6364c';
export const blackSqueeze = '#f4f9fc';
export const tenn = '#d95a00';
export const denim = '#1262b3';
export const lochmara = '#0077cc';
export const solitude = '#e6ecf2';
export const lima = '#7ed321';
export const lima_dark = '#71C219';
export const christi = '#71c219';
export const white = '#fff';

export const ui_color = lochmara;

const createThemeWithSource = (src: ThemeOptions) => {
  const theme = createTheme(src);
  return { ...theme, src };
};

const theme = createThemeWithSource({
  typography: {
    // converted to rem, see https://mui.com/material-ui/customization/typography/#font-size
    fontSize: 14,
    // by default, MuiButton should not be all uppercase
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: denim,
    },
    secondary: {
      main: lima,
      contrastText: white,
    },
    error: {
      main: tenn,
    },
  },
  components: {
    //  https://mui.com/material-ui/migration/migration-v4/#variant-prop
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 1,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: 5,
          paddingBottom: 5,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: lima,
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: lima_dark,
            color: 'white',
          },
          '&:active': {
            backgroundColor: lima_dark,
            color: 'white',
          },
        },
        containedSecondary: {
          '&:hover': {
            color: 'white',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.1em',
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          color: 'black',
          paddingTop: 10,
          marginLeft: 5,
          MuiButton: {
            paddingBottom: '1px!important',
          },
        },
        indicator: {
          backgroundColor: ui_color,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'black',
          '&.Mui-selected': {
            color: ui_color,
            fontWeight: 'bold',
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: blackSqueeze,
          borderRadius: 2,
          height: 22,
          margin: 2,
        },
        deleteIcon: {
          height: 14,
          marginRight: 0,
        },
        label: {
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
});

export const errorTheme = createTheme({
  ...theme.src,
  palette: {
    ...theme.src.palette,
    primary: {
      main: amaranth,
    },
    secondary: theme.src.palette.error,
  },

  components: {
    ...theme.src.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: amaranth,
          fontWeight: 'bold',
          '&:hover': {
            color: 'white',
          },
          '&:active': {
            color: 'white',
          },
        },
        containedSecondary: {
          backgroundColor: 'rgb(128, 128, 255)', //  $lima:  // #7ED321
        },
      },
    },
  },
});

export default theme;
